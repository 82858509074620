/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import LOGO from "./images/logo.png";
import { useTranslation } from "react-i18next";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactPlayer from "react-player";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import {
  DOWNLOAD_VIDEO_API,
  IS_LOCAL,
  SOLUTION_ADS,
  STORY_LINES,
} from "../const";
import axios from "axios";
import { useParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import Swal from "sweetalert2";

interface UserVideoRes {
  statusCode: number;
  video: string;
  timeout?: number;
  msg?: string;
  errorMsg?: string;
}

const Download: React.FC = () => {
  const { t } = useTranslation();
  const { userId, storyId } = useParams();
  const [loadingData, setLoadingData] = useState(true);
  const [videoDone, setVideoDone] = useState(false);
  // const COUNT_DOWN_TIME = 10; //Seconds
  const [countDownTime, setCountDownTime] = useState(-1);
  const [videoLink, setVideoLink] = useState("");

  const convertToTime = (num: number) => {
    const minutes = Math.floor(num / 60);
    const seconds = num % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  const getUserVideo = async () => {
    setLoadingData(true);
    await axios
      .get(`${DOWNLOAD_VIDEO_API}/${userId}/data`, {
        headers: {
          "x-api-key": "98765432109876543210",
        },
      })
      .then((res) => {
        console.info("res:", res);
        setLoadingData(false);
        const resData: UserVideoRes = res.data;
        if (resData.statusCode === 200) {
          setVideoDone(true);
          setVideoLink(resData.video);
          console.info("success:", resData.msg);
        } else if (resData.statusCode === 404) {
          console.info("no inference:", resData.msg);
        } else if (resData.statusCode === 204) {
          console.info("in progress:", resData.msg);
          // Show Progress Bar
          if (resData.timeout) {
            setCountDownTime(resData.timeout ? resData.timeout + 10 : -1);
          } else {
            setCountDownTime(5 * 60);
          }
        } else {
          console.error("error:", resData.errorMsg);
          Swal.fire({
            icon: "error",
            title: t("error"),
            // text: error.message,
          });
        }
      })
      .catch((error) => {
        setCountDownTime(-1);
        setLoadingData(false);
        console.error("ERROR:", error);
        if (IS_LOCAL) {
          Swal.fire({
            icon: "error",
            title: t("error"),
            text: error.message,
          });
        }
        return;
      });
  };

  const renderTime = ({ remainingTime }: any) => {
    if (remainingTime === 0) {
      setVideoDone(true);
      return <div className="timer">{t("download.done")}</div>;
    }
    return (
      <div className="timer">
        <div className="value">{convertToTime(remainingTime)}</div>
      </div>
    );
  };

  useEffect(() => {
    getUserVideo();
  }, []);

  return (
    <div className="download-page">
      <div className="download-header">
        <div className="pysx-logo">
          <div className="pysx-logo-img">
            <div>
              <img src="/aws-logo.png" height="20" alt="aws logo" />
            </div>
            <div>
              <img src="/ym-logo.png" height="20" alt="yang mei logo" />
            </div>
          </div>
        </div>
        <div className="pysx-logo">
          <div>
            <img width="40" src={LOGO} alt="logo" />
          </div>
          <div className="pysx-logo-title">{t("name")}</div>
        </div>
      </div>

      <div className="download-container">
        <div className="header-bg"></div>
        {loadingData ? (
          <RingLoader />
        ) : (
          <>
            {videoDone ? (
              <div>{t("download.finish")}</div>
            ) : (
              <div>{t("download.processing")}</div>
            )}
            {videoDone ? (
              <div>
                <ReactPlayer
                  playsinline={true}
                  height="auto"
                  controls
                  width="100%"
                  url={`${videoLink}`}
                />
                {/* <div className="download-action-button">
                <button className="small">点击下载视频</button>
              </div> */}
              </div>
            ) : (
              <div>
                {countDownTime > 0 && (
                  <CountdownCircleTimer
                    isPlaying
                    duration={countDownTime}
                    colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                    colorsTime={[10, 6, 3, 0]}
                    onComplete={() => {
                      getUserVideo();
                    }}
                  >
                    {renderTime}
                  </CountdownCircleTimer>
                )}
              </div>
            )}
          </>
        )}
      </div>

      <div className="bottom-bg"></div>
      <div className="download-footer">
        <Swiper
          autoplay={{
            delay: 5000,
          }}
          slidesPerView={1}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          spaceBetween={50}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {[
            {
              name: t(
                STORY_LINES.find(
                  (element) => element.id === parseInt(storyId ?? "0")
                )?.name || ""
              ),
              desc: t(
                STORY_LINES.find(
                  (element) => element.id === parseInt(storyId ?? "0")
                )?.desc || ""
              ),
              link: "",
            },
            ...SOLUTION_ADS,
          ].map((element) => {
            return (
              <SwiperSlide key={element.name}>
                <div className="solution-slide">
                  <div className="solution-title">{t(element.name)}</div>
                  <div className="solution-desc">
                    {t(element.desc)}
                    {element.link && (
                      <a href={element.link} target="_blank" rel="noreferrer">
                        {t("download.learnMore")}
                      </a>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Download;
