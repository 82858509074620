/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ChooseStory from "./steps/ChooseStory";
import PlayVideoAndRecord from "./steps/PlayVideoAndRecord";
import axios from "axios";
import { API_URL, IS_LOCAL } from "../const";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { RingLoader } from "react-spinners";

export interface StoryType {
  id: number;
}

const Play = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [curStory, setCurStory] = useState<StoryType>({
    id: 0,
  });
  const [userId, setUserId] = useState<string>("");
  const [loadingUser, setLoadingUser] = useState(true);

  function getUserId() {
    setLoadingUser(true);
    axios
      .post(`${API_URL}/user`)
      .then(function (response) {
        setUserId(response.data.userid);
        setLoadingUser(false);
      })
      .catch(function (error) {
        setLoadingUser(false);
        Swal.fire({
          icon: "error",
          title: t("error"),
          text: error.message,
        });
      })
      .finally(function () {
        // always executed
      });
    return "test";
  }

  // component did mount
  useEffect(() => {
    if (IS_LOCAL) {
      getUserId();
    } else {
      setLoadingUser(false);
    }
  }, []);

  return (
    <div className="page-container">
      {loadingUser ? (
        <div className="page-loading">
          <RingLoader />
        </div>
      ) : (
        <div>
          {currentStep === 0 && (
            <ChooseStory
              curStory={curStory}
              goToNextPage={() => {
                setCurrentStep(1);
              }}
              changeCurStory={(id) => {
                setCurStory((prev) => {
                  return {
                    ...prev,
                    id: id,
                  };
                });
              }}
            />
          )}
          {currentStep === 1 && (
            <PlayVideoAndRecord
              userId={userId}
              curStory={curStory}
              goToNextPage={() => {
                setCurrentStep(2);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Play;
