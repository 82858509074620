import { format } from "date-fns";

import STORY_IMG_1 from "./pages/images/story1.webp";
import STORY_IMG_2 from "./pages/images/story2.webp";
import STORY_IMG_3 from "./pages/images/story3.webp";
import STORY_IMG_4 from "./pages/images/story4.webp";

export const RECORD_VIDEO_TIME_IN_SECONDS = 5;
export const STORY_VIDEO_PLAY_TIME = 10000;

export const IS_LOCAL = false;
export const TEST_USER_ID = "658890b4-a4ef-43a5-ba88-4fab4f7e59f2";
// export const API_URL = "http://ec2-3-86-167-241.compute-1.amazonaws.com:7860";
export const API_URL = "http://127.0.0.1:8080";
export const QR_CODE_LINK = "https://pysx.demo.solutions.aws.a2z.org.cn";
export const WEBSOCKET_SERVER =
  "https://websocket.photo-magic.m.solutions.aws.a2z.org.cn/";
export const DOWNLOAD_VIDEO_API =
  "https://azpmkzq63m.execute-api.us-east-1.amazonaws.com/prod/user";
export const getCurrentTime = (): string => {
  return format(new Date(), "yyyy-MM-dd HH:mm:ss");
};

export const STORY_LINES = [
  {
    id: 1,
    image: STORY_IMG_1,
    name: "story.story1Name",
    desc: "story.story1Desc",
    action: "story.story1Action",
    countDown: 22,
    trueId: 3,
  },
  {
    id: 2,
    image: STORY_IMG_2,
    name: "story.story2Name",
    desc: "story.story2Desc",
    action: "story.story2Action",
    countDown: 28,
    trueId: 4,
  },
  {
    id: 3,
    image: STORY_IMG_3,
    name: "story.story3Name",
    desc: "story.story3Desc",
    action: "story.story3Action",
    countDown: 34,
    trueId: 1,
  },
  {
    id: 4,
    image: STORY_IMG_4,
    name: "story.story4Name",
    desc: "story.story4Desc",
    action: "story.story4Action",
    countDown: 33,
    trueId: 2,
  },
];

export const SOLUTION_ADS = [
  {
    name: "solution.solution1Name",
    desc: "solution.solution1Desc",
    link: "https://www.amazonaws.cn/solutions/technology/ai-ml/stable-diffusion-aws-extension/",
  },
  {
    name: "solution.solution2Name",
    desc: "solution.solution2Desc",
    link: "https://www.amazonaws.cn/solutions/centralized-logging-with-opensearch/",
  },
  {
    name: "solution.solution3Name",
    desc: "solution.solution3Desc",
    link: "https://www.amazonaws.cn/solutions/technology/security/sensitive-data-protection-on-amazon-web-services/",
  },
];
