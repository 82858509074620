/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useKeyPress from "../../hooks/useKeypress";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import LOGO from "../images/logo.png";
import CN from "../images/lang/cn.svg";
import EN from "../images/lang/us.svg";

// import ReactCountryFlag from "react-country-flag";

export const ZH_LANGUAGE_LIST = ["zh", "zh-cn", "zh_CN", "zh-CN"];
const ZH_TEXT = "简体中文";
const EN_TEXT = "English(US)";

const LANGUAGE_ITEMS = [
  {
    id: "en",
    text: EN_TEXT,
    flag: <img src={EN} alt="EN" width="18" />,
  },
  {
    id: "zh",
    text: ZH_TEXT,
    flag: <img src={CN} alt="CN" width="18" />,
  },
];

interface HeaderProps {
  hideRestart?: boolean;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { hideRestart } = props;
  const { t, i18n } = useTranslation();
  const [showChangeLang, setShowChangeLang] = useState(false);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const capitalCPressed = useKeyPress("C");
  const smallCPressed = useKeyPress("c");

  const capitalEPressed = useKeyPress("E");
  const smallEPressed = useKeyPress("e");

  // Change Language
  useEffect(() => {
    if (capitalCPressed || smallCPressed) {
      i18n.changeLanguage("zh");
    }
  }, [capitalCPressed, smallCPressed]);

  useEffect(() => {
    if (capitalEPressed || smallEPressed) {
      i18n.changeLanguage("en");
    }
  }, [capitalEPressed, smallEPressed]);

  useEffect(() => {
    if (ZH_LANGUAGE_LIST.includes(i18n.language)) {
      changeLanguage("zh");
    }
  }, []);

  return (
    <div className="pysx-page-header">
      <div className="pysx-logo">
        <div className="pysx-logo-img">
          <img src="/aws-logo.png" height="40" alt="aws logo" />
          <img src="/ym-logo.png" height="40" alt="yang mei logo" />
          {/* <div>亚马逊云科技</div>
      <div>中央美术学院</div> */}
        </div>
      </div>
      <div className="pysx-logo">
        <div>
          <img width="40" src={LOGO} alt="logo" />
        </div>
        <div className="pysx-logo-title">{t("name")}</div>
      </div>
      <div className="pysx-actions">
        <div className="change-language pr">
          <div
            className="cur-lang"
            onClick={() => {
              setShowChangeLang(true);
            }}
          >
            <span>
              {
                LANGUAGE_ITEMS.find((element) => element.id === i18n.language)
                  ?.flag
              }
            </span>
            <span>
              {ZH_LANGUAGE_LIST.includes(i18n.language) ? ZH_TEXT : EN_TEXT}
            </span>
            <span className="triangle-down"></span>
          </div>
          {showChangeLang && (
            <div className="lang-selection">
              {LANGUAGE_ITEMS.map((element) => {
                return (
                  <div
                    onClick={() => {
                      changeLanguage(element.id);
                      setShowChangeLang(false);
                    }}
                    className={classNames("lang-item")}
                    key={element.id}
                  >
                    <span>{element.flag}</span>
                    <span>{element.text}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {!hideRestart && (
          <div
            className="restart"
            onClick={() => {
              window.location.reload();
            }}
          >
            {t("button.restart")}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
