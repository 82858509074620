import React, { useEffect } from "react";
import useKeyPress from "../hooks/useKeypress";
import Header from "./comp/Header";
import { useTranslation } from "react-i18next";

const Term: React.FC = () => {
  const capitalXPressed = useKeyPress("X");
  const smallXPressed = useKeyPress("x");
  const { t } = useTranslation();

  useEffect(() => {
    if (capitalXPressed || smallXPressed) {
      window.close();
    }
  }, [capitalXPressed, smallXPressed]);

  return (
    <div>
      <Header hideRestart />
      <div className="term-container">
        <h1 className="text-center">{t("term.name")}</h1>
        <p className="text-center mt-10">{t("term.desc")}</p>
        <div className="demo-area term-of-use">
          <div>
            <div>{t("term.p1")}</div>
            <p>{t("term.p2")}</p>
            <ul>
              <li>{t("term.p3")}</li>
              <li>{t("term.p4")}</li>
              <li>{t("term.p5")}</li>
              <li>{t("term.p6")}</li>
              <li>{t("term.p7")}</li>
            </ul>
            <p>{t("term.p8")}</p>
            <div>{t("term.p9")}</div>
            <div>{t("term.p10")}</div>
            <div>{t("term.p11")}</div>
            <div>{t("term.p12")}</div>
            <div>{t("term.p13")}</div>
            <div>{t("term.p14")}</div>
            <div>{t("term.p15")}</div>
            <div>{t("term.p16")}</div>
            <div>{t("term.p17")}</div>
            <div>{t("term.p18")}</div>
            <div>{t("term.p19")}</div>
            <div>{t("term.p20")}</div>
            <div>{t("term.p21")}</div>
            <div>{t("term.p22")}</div>
            <div>{t("term.p23")}</div>
            <div>{t("term.p24")}</div>
            <div>{t("term.p25")}</div>
            <div>{t("term.p26")}</div>
            <div>{t("term.p27")}</div>
            <div>{t("term.p28")}</div>
            <div>{t("term.p29")}</div>
            <div>{t("term.p30")}</div>
            <div>{t("term.p31")}</div>
            <div>{t("term.p32")}</div>
            <div>{t("term.p33")}</div>
            <div>{t("term.p34")}</div>
            <div>{t("term.p35")}</div>
            <div>{t("term.p36")}</div>
            <div>{t("term.p37")}</div>
            <div>{t("term.p38")}</div>
            <div>{t("term.p39")}</div>
            <div>{t("term.p40")}</div>
            <div>{t("term.p41")}</div>
            <div>{t("term.p42")}</div>
            <div>{t("term.p43")}</div>
            <div>{t("term.p44")}</div>
            <div>{t("term.p45")}</div>
            <div>{t("term.p46")}</div>
            <div>{t("term.p47")}</div>
            <div>{t("term.p48")}</div>
            <div>{t("term.p49")}</div>
            <div>{t("term.p50")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Term;
