/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect } from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Play from "./pages/Play";
import Term from "./pages/Term";
import { useTranslation } from "react-i18next";
import Download from "./pages/Download";

function App() {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t("title");
  }, []);

  return (
    <div className="App">
      <Suspense fallback={null}>
        <Router>
          <Routes>
            <Route path="/" element={<Play />} />
            <Route path="/term-of-use" element={<Term />} />
            <Route path="/download/:userId/:storyId" element={<Download />} />
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
