/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { StoryType } from "../Play";
import { useTranslation } from "react-i18next";
// import ReactCountryFlag from "react-country-flag";
import Swal from "sweetalert2";
import useKeyPress from "../../hooks/useKeypress";
import { STORY_LINES } from "../../const";
import Header from "../comp/Header";

interface ChooseStoryProps {
  curStory: StoryType;
  goToNextPage: () => void;
  changeCurStory: (id: number) => void;
}

const ChooseStory: React.FC<ChooseStoryProps> = (props: ChooseStoryProps) => {
  const { curStory, goToNextPage, changeCurStory } = props;
  const { t } = useTranslation();
  const [isRandom, setIsRandom] = useState(true);
  const [randomIndex, setRandomIndex] = useState(0);
  const [startRandom, setStartRandom] = useState(false);
  const [storySelected, setStorySelected] = useState(false);
  const [agreeTerm, setAgreeTerm] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    changeCurStory(randomIndex);
  }, [randomIndex]);

  useEffect(() => {
    if (startRandom) {
      const intervalId = setInterval(() => {
        const tmpIndex = Math.floor(Math.random() * 4) + 1;
        setRandomIndex(tmpIndex);
        changeCurStory(tmpIndex);
        // setRandomIndex((prevIndex) => (prevIndex + 1) % 4);
      }, 100);
      setTimeout(() => {
        clearInterval(intervalId);
        setStartRandom(false);
        setStorySelected(true);
      }, 2000);
      return () => clearInterval(intervalId);
    }
  }, [startRandom]);

  const clickRandom = (checked: boolean) => {
    setIsRandom(checked);
    setStorySelected(false);
    changeCurStory(0);
  };

  const clickStart = () => {
    if (isRandom) {
      setStartRandom(true);
    } else {
      if (curStory.id <= 0) {
        Swal.fire({
          showConfirmButton: false,
          allowOutsideClick: true,
          icon: "info",
          title: t("storyError"),
        });
        setShowAlert(true);
        return;
      }
    }
  };

  // Below is keyboard control
  const backspacePressed = useKeyPress("Backspace");
  const escapePressed = useKeyPress("Escape");
  const enterPressed = useKeyPress("Enter");

  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const arrowLeftPressed = useKeyPress("ArrowLeft");
  const arrowRightPressed = useKeyPress("ArrowRight");

  const capitalTPressed = useKeyPress("T");
  const smallTPressed = useKeyPress("t");

  // Open Term Page
  useEffect(() => {
    if (capitalTPressed || smallTPressed) {
      window.open("/term-of-use", "_blank");
    }
  }, [capitalTPressed, smallTPressed]);

  // ArrowUp to cancel random
  useEffect(() => {
    if (arrowUpPressed) {
      clickRandom(false);
    }
  }, [arrowUpPressed]);

  // ArrowDown to check random
  useEffect(() => {
    if (arrowDownPressed) {
      clickRandom(true);
    }
  }, [arrowDownPressed]);

  // Enter press
  useEffect(() => {
    if (enterPressed) {
      if (curStory.id) {
        goToNextPage();
      } else {
        clickStart();
      }
    }
  }, [enterPressed]);

  // Select Story When Click Left / Right
  useEffect(() => {
    if (!isRandom) {
      if (arrowLeftPressed) {
        if (curStory.id <= 1) {
          changeCurStory(1);
        } else {
          changeCurStory(curStory.id - 1);
        }
        setStorySelected(true);
      }
      if (arrowRightPressed) {
        if (curStory.id >= 4) {
          changeCurStory(4);
        } else {
          changeCurStory(curStory.id + 1);
        }
        setStorySelected(true);
      }
    }
  }, [arrowLeftPressed, arrowRightPressed]);

  // Restart when press escape
  useEffect(() => {
    if (escapePressed || backspacePressed) {
      if (showAlert) {
        Swal.close();
      } else {
        window.location.reload();
      }
    }
  }, [escapePressed, backspacePressed]);

  return (
    <div>
      <Header />
      <div className="pysx-choose-story-header">
        <div className="left-side">{t("choose")}</div>
        <div className="right-side">
          <label className="container">
            {t("random")}
            <input
              type="checkbox"
              checked={isRandom}
              onChange={(e) => {
                clickRandom(e.target.checked);
              }}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
      <div className="pysx-choose-story">
        {STORY_LINES.map((element) => {
          return (
            <div
              onClick={() => {
                if (isRandom) {
                  return;
                } else {
                  changeCurStory(element.id);
                  setStorySelected(true);
                }
              }}
              key={element.id}
              className={classNames("story-item", {
                active: curStory.id === element.id,
              })}
            >
              <div
                className="story-cover"
                style={{ backgroundImage: "url('" + element.image + "')" }}
              ></div>
              <div className="pysx-cs-desc">
                <div className="title">{t(element.name)}</div>
                <div className="description">{t(element.desc)}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="pysx-action-button">
        {storySelected ? (
          <button
            onClick={() => {
              goToNextPage();
            }}
          >
            {t("button.viewStory", {
              story: t(
                STORY_LINES.find((element) => element.id === curStory.id)
                  ?.name || ""
              ),
            })}
          </button>
        ) : (
          <button
            disabled={!agreeTerm}
            onClick={() => {
              clickStart();
            }}
          >
            {t("button.startGame")}
          </button>
        )}

        <p className="privacy-policy">
          <input
            type="checkbox"
            checked={agreeTerm}
            onChange={(e) => {
              setAgreeTerm(e.target.checked);
            }}
          />
          &nbsp;{t("agree")}&nbsp;
          <a href="/term-of-use" target="_blank">
            {t("policy")}
          </a>
        </p>
      </div>
    </div>
  );
};

export default ChooseStory;
