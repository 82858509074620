/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useRecordWebcam } from "react-record-webcam";
import classNames from "classnames";
import QRCode from "react-qr-code";
import { StoryType } from "../Play";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import BarLoader from "react-spinners/BarLoader";
import "animate.css";
import axios from "axios";
import Swal from "sweetalert2";
import {
  API_URL,
  RECORD_VIDEO_TIME_IN_SECONDS,
  // STORY_VIDEO_PLAY_TIME,
  IS_LOCAL,
  QR_CODE_LINK,
  STORY_LINES,
  TEST_USER_ID,
  WEBSOCKET_SERVER,
  getCurrentTime,
} from "../../const";
import { useTranslation } from "react-i18next";
import useKeyPress from "../../hooks/useKeypress";
import socketIOClient from "socket.io-client";

interface PlayVideoAndRecordProps {
  userId: string;
  curStory: StoryType;
  goToNextPage: () => void;
}

const PlayVideoAndRecord: React.FC<PlayVideoAndRecordProps> = (
  props: PlayVideoAndRecordProps
) => {
  const { userId, curStory } = props;
  const { t } = useTranslation();
  const STORY_VIDEO_PLAY_TIME =
    (STORY_LINES.find((element) => element.id === curStory.id)?.countDown ||
      0) * 1000;
  const { width, height } = useWindowSize();
  const recordWebcam = useRecordWebcam({ frameRate: 60 });
  const [countdown, setCountdown] = useState(RECORD_VIDEO_TIME_IN_SECONDS);
  const [showRecorder, setShowRecorder] = useState(false);
  const [startCountdown, setStartCountdown] = useState(false);

  const [showComplete, setShowComplete] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);

  const [webSocket, setWebSocket] = useState<any>(null);
  const generateQRCode = () => {
    setTimeout(() => {
      setShowComplete(false);
      setShowQRCode(true);
      sendQRCodeToWebServer();
    }, 3000);
  };

  const connectSocketIO = () => {
    // const socket = socketIOClient(ENDPOINT);
    const socket = socketIOClient(WEBSOCKET_SERVER, {
      transports: ["websocket", "polling", "flashsocket"],
      secure: true,
    });
    // socket.emit()
    setWebSocket(socket);
    socket.on("ConnectTime", (data) => {
      console.info("data:", data);
    });
    socket.on("disconnect", (reason) => {
      console.info("reason:", reason);
    });
  };

  const buildQRCodeLink = () => {
    return `${QR_CODE_LINK}/download/${userId || TEST_USER_ID}/${curStory.id}`;
  };

  useEffect(() => {
    connectSocketIO();
  }, []);

  const sendQRCodeToWebServer = () => {
    webSocket.emit("QRCode", {
      message:
        t(STORY_LINES.find((story) => story.id === curStory.id)?.name || "") ||
        "",
      time: getCurrentTime(),
      printerNum: "1",
      qrCodeUrl: buildQRCodeLink(),
    });
  };

  const saveFile = async () => {
    const blob = await recordWebcam.getRecording();
    console.info("blob:", blob);
    if (blob) {
      let formData = new FormData();
      formData.append(
        "the_file",
        blob,
        `test-${new Date().toUTCString()}.webm`
      );
      axios
        .post(
          `${API_URL}/user/${userId || TEST_USER_ID}/input/${
            STORY_LINES.find((element) => element.id === curStory.id)?.trueId
          }`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((resp) => {
          console.log(resp);
          setShowComplete(false);
          setShowQRCode(true);
          sendQRCodeToWebServer();
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            icon: "error",
            title: t("error"),
            text: error.message,
          });
        });
    }
  };

  useEffect(() => {
    if (STORY_VIDEO_PLAY_TIME > 0) {
      recordWebcam.open();
      setTimeout(() => {
        setShowRecorder(true);
      }, STORY_VIDEO_PLAY_TIME);
    }
  }, [STORY_VIDEO_PLAY_TIME]);

  useEffect(() => {
    if (startCountdown) {
      const interval = setInterval(() => {
        if (countdown <= 0) {
          clearInterval(interval);
        }
        setCountdown((countdown) => (countdown - 1 <= 0 ? 0 : countdown - 1));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [startCountdown]);

  useEffect(() => {
    if (startCountdown && countdown <= 0) {
      recordWebcam.stop();
      setStartCountdown(false);
    }
  }, [countdown]);

  useEffect(() => {
    let audioPlayer = new Audio("/video/bgm.m4a");
    audioPlayer.loop = true;
    if (showRecorder) {
      audioPlayer.play();
    } else {
      audioPlayer.pause();
    }
    return () => {
      audioPlayer.pause();
    };
  }, [showRecorder]);

  useEffect(() => {
    let audioPlayer = new Audio("/video/cheers.m4a");
    audioPlayer.loop = false;
    if (showComplete) {
      audioPlayer.play();
    } else {
      audioPlayer.pause();
    }
    return () => {
      audioPlayer.pause();
    };
  }, [showComplete]);

  // Click Start Recording Button
  const startRecordingVideo = () => {
    setCountdown(RECORD_VIDEO_TIME_IN_SECONDS);
    setStartCountdown(true);
    recordWebcam.start();
  };

  // Re Capture Video
  const reCaptureVideo = () => {
    setCountdown(RECORD_VIDEO_TIME_IN_SECONDS);
    recordWebcam.retake();
  };

  // Complete Video
  const completeVideo = () => {
    setShowRecorder(false);
    setShowComplete(true);
    if (IS_LOCAL) {
      saveFile();
    } else {
      generateQRCode();
    }
  };

  // Below is keyboard control
  const backspacePressed = useKeyPress("Backspace");
  const escapePressed = useKeyPress("Escape");
  const enterPressed = useKeyPress("Enter");

  // const arrowUpPressed = useKeyPress("ArrowUp");
  // const arrowDownPressed = useKeyPress("ArrowDown");
  const arrowLeftPressed = useKeyPress("ArrowLeft");
  const arrowRightPressed = useKeyPress("ArrowRight");

  useEffect(() => {
    if (enterPressed) {
      if (recordWebcam.status === "OPEN") {
        startRecordingVideo();
      }
      if (recordWebcam.status === "PREVIEW") {
        completeVideo();
      }
      if (showQRCode) {
        window.location.reload();
      }
    }
  }, [enterPressed]);

  useEffect(() => {
    if ((escapePressed || backspacePressed) && showQRCode) {
      window.location.reload();
    }
  }, [escapePressed, backspacePressed]);

  useEffect(() => {
    if (arrowLeftPressed && recordWebcam.status === "PREVIEW") {
      reCaptureVideo();
    }
  }, [arrowLeftPressed]);

  useEffect(() => {
    if (arrowRightPressed && recordWebcam.status === "PREVIEW") {
      completeVideo();
    }
  }, [arrowRightPressed]);

  return (
    <div>
      <div
        className={classNames("pysx-video-record", {
          show: showRecorder,
        })}
      >
        <div className="left-description">
          <span className="record-title">{t("recording")}</span>
          {/* <div className="record-title-en">{t("recordingEn")}</div> */}
          <span className="action-title">{t("tryMove")}</span>
          {/* <div className="action-title-en">{t("tryMoveEn")}</div> */}
        </div>
        <div className="action-proposal">
          <span>{t("story.actionProposal")}: </span>{" "}
          {t(
            STORY_LINES.find((element) => element.id === curStory.id)?.action ||
              ""
          )}
        </div>
        <div className="right-recorder">
          <div className="pr">
            {/* Recording Video */}
            <div
              className={classNames({
                hide: recordWebcam.status === "PREVIEW",
              })}
            >
              {recordWebcam.status === "RECORDING" && (
                <span className="recording-icon"></span>
              )}

              <div className="pysx-recording-countdown">{countdown}</div>
              <video
                width="100%"
                // height="540"
                ref={recordWebcam.webcamRef}
                autoPlay
                muted
              />
            </div>

            {/* Preview Video */}
            <div
              className={classNames({
                hide: recordWebcam.status !== "PREVIEW",
              })}
            >
              <div className="pysx-recording-countdown small">
                {t("preview")}
              </div>
              <video
                controls
                width="100%"
                // height="540"
                ref={recordWebcam.previewRef}
                autoPlay
                muted
                loop
              />
            </div>

            {/* <p>Camera status: {recordWebcam.status}</p>
            <button onClick={recordWebcam.open}>Open camera</button>
            <button
              onClick={() => {
                recordWebcam.start();
              }}
            >
              Start recording
            </button>
            <button onClick={recordWebcam.stop}>Stop recording</button>
            <button onClick={recordWebcam.retake}>Retake recording</button>
            <button onClick={recordWebcam.download}>Download recording</button>
            <button onClick={saveFile}>Save file to server</button> */}

            <div className="pysx-action-button">
              {recordWebcam.status !== "PREVIEW" &&
                recordWebcam.status !== "RECORDING" && (
                  <button
                    className="small"
                    disabled={recordWebcam.status !== "OPEN"}
                    onClick={() => {
                      startRecordingVideo();
                    }}
                  >
                    {t("button.startRecord")}
                  </button>
                )}

              {recordWebcam.status === "RECORDING" && (
                <>
                  <button
                    className="small"
                    disabled
                    style={{ cursor: "not-allowed" }}
                  >
                    {t("button.recording")}
                  </button>
                </>
              )}

              {recordWebcam.status === "PREVIEW" && (
                <>
                  <button
                    className="small"
                    onClick={() => {
                      reCaptureVideo();
                    }}
                  >
                    {t("button.reCapture")}
                  </button>
                  <button
                    className="small"
                    onClick={() => {
                      completeVideo();
                    }}
                  >
                    {t("button.finishRecord")}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {showComplete && (
        <div className="pysx-complete-recorder">
          <div className="pysx-cmeara-bg"></div>
          <div className="title">{t("finish")}</div>
          <div className="en-title">{t("finishEn")}</div>
          <div className="loading">
            <div>{t("genQRCode")}</div>
            <BarLoader color={"#222"} loading={true} />
          </div>
        </div>
      )}

      {showQRCode && (
        <div className="pysx-complete-recorder">
          <div className="pysx-qr-code">
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={buildQRCodeLink()}
              viewBox={`0 0 256 256`}
            />
            <div className="story-name">
              {t(
                STORY_LINES.find((story) => story.id === curStory.id)?.name ||
                  ""
              ) || ""}
            </div>
          </div>
          <div className="title">{t("scanCode")}</div>
          <div className="en-title">{t("scanCodeEn")}</div>
          <div className="pysx-action-button">
            <button
              onClick={() => {
                window.location.reload();
              }}
            >
              {t("button.rePlay")}
            </button>
          </div>
        </div>
      )}
      {(showComplete || showQRCode) && (
        <>
          <Confetti numberOfPieces={300} width={width} height={height} />
          <div className={classNames("pysx-success")}></div>
        </>
      )}
      <div className="video-background">
        <video autoPlay>
          <source src={`/video/story${curStory.id}.mp4`} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default PlayVideoAndRecord;
